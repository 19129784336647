const dirLogoSx = {
    height: {xs: "25vw", sm: "20vw", md: 150},
    width: {xs: "20vw", sm: "16vw", md: 120},
    maxHeight: 150,
    maxWidth: 120
}

const dirMenuSx = {
    padding: {xs: "0 8px 5px", sm: "0 2vw 10px", md: "0 30px 10px 15px"},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    width: {xs: "80vw", sm: "83vw", md: 835},
    height: {xs: "25vw", sm: "20vw", md: 150},
    fontSize: {xs: "2.3vw", md: 17},
    maxHeight: 150,
    alignItems: "center"
}

const mainSx = {
    padding: "0", 
    m: "9px auto 0", 
    color: "primary.main", 
    width: {xs: "100%", md: 960},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    // bgcolor: "rgba(236, 239, 241, 0.95)",
}

const posterSx = {
    position: "relative",
    // height: {xs: "16.66vw", md: 150}, 
    maxHeight: 150,
    // width: {xs: "11.22vw", md: 101}, 
    mr: "10px", 
}

const ratesSx = {
    fontSize: { xs: '2.4vw', sm: "1.65vw", md: 16}, 
    width: {xs: '27vw', sm: "20.83vw", md: 200}, 
    fontWeight: 600
}

const aggLogoSx = {
    width: {xs: 12, sm: 14, md: 17},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.2)",
        opacity: "70%"}
}

const onlLogoSx = {
    padding: "2px", 
    width: { xs: "6.0vw", sm: "5.0vw", md: 45},
    maxWidth: {sm: 40, md: 45},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.1)",
        opacity: "70%"}
}

const subLogoSx = {
    padding: "2px", 
    width: { xs: "6.0vw", sm: "5.0vw", md: 45},
    maxWidth: {sm: 40, md: 45},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.1)"}
}

const coonSx = {
    height: { xs: "6.5vw", sm: "5.5vw", md: 45},
    maxHeight: {sm: 36, md: 50}
}

export { dirLogoSx, dirMenuSx, mainSx, posterSx, ratesSx, aggLogoSx, onlLogoSx, subLogoSx, coonSx}