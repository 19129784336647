import { Grid } from '@mui/material';
import { headlineSx } from "./menuStyle";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DonCheck from "./DonCheckPg"
import { useNavigate, Link as RouterLink } from "react-router-dom";

const DonateBlock = ({coonbase}) => {

    const navigate = useNavigate()

    const full = coonbase

    return (
        <Grid>
            <Grid container sx={headlineSx}>
                <div style={{margin: "auto 10px"}}>
                    <RouterLink className="left" to="/donations" onClick={(e) => {e.preventDefault(); navigate("donations")}}>
                        DONATE FOR NEW ONES
                    </RouterLink>
                </div>
            </Grid>
            <Grid container item sm={12} sx={{m: "0 auto 10px", width: {xs: "100%", sm: 960}}}>
                <Grid container item xs sx={{height: {xs: "18.75vw", md: 180}, m: "0 auto"}}>
                    {full.slice(0, 9).map(m => {

                        const ids = JSON.parse(m.ids)
                        const main = JSON.parse(m.maininfo)
                        const release = JSON.parse(m.releaseinfo)
                        const posters = JSON.parse(m.posters)
                        const don = m.don_sum

                        const regex = /\D/g
                        let cur_sum, full_sum, percent
                        m.percent < 100 && (cur_sum = don.replaceAll(' ', ' ').replace('₽ from', '₽ out of').split(' ₽ out of ')[0].replaceAll(' ', '').replace(',', '.'))
                        m.percent < 100 && (full_sum = don.replaceAll(' ', ' ').replace('₽ from', '₽ out of').split(' ₽ out of ')[1].replace(regex, ''))
                        m.percent < 100 ? percent = Math.round(cur_sum / full_sum * 100) : percent = m.percent
                        console.log(cur_sum, full_sum)

                        return (
                        <Grid key={ids.imdb_id} sx={{height: "100%"}}>
                            <Grid   container item sx={{height: {xs: "16.66vw", md: 153}, width: {xs: "11.1vw", md: 106.6}}}>
                                <RouterLink className="moviecard" to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    <LazyLoadImage
                                    height="100%"
                                    width="100%"
                                    effect="blur"
                                    title={main.imdb_title_exp + " (" + release.year + ")"}
                                    style={{objectFit: "cover"}}
                                    placeholderSrc={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                    src={'/posters/' + ids.export_name + '_' + posters.coon_default + '.jpg'}
                                    />
                                </RouterLink>
                            </Grid>
                            <DonCheck fulldon={percent} wallet={m.wallet_id} imdb_id={ids.imdb_id}></DonCheck>
                        </Grid>
                        )}
                    )}
                </Grid>
            </Grid>
        </Grid>

    )
}

export default DonateBlock;
