import { useState, useEffect } from 'react';
import { Grid } from "@mui/material"
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { headlineSx, awLogoSx } from "./awStyle"
import axios from 'axios';

const AllAwards = () => {

    const navigate = useNavigate()

    const [event, setEvent] = useState([])

    useEffect(() => {
        const getBase = async () => {
            const res = await axios.get(`/api/awards/events`, {
                responseType: 'json',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                }
              })
            setEvent(res.data)
        }
        getBase()
    }, [])

    return (
        <>
        <Grid container sx={headlineSx}>
            <div style={{margin: "auto 10px"}}>AWARDS</div>
        </Grid>
        <Grid container sx={headlineSx}>
            <div style={{margin: "auto 10px"}}>FESTIVALS</div>
        </Grid>
        <Grid container sx={{justifyContent: 'left'}}>
        {event.map(ev => {
        return (
            <Grid key={ev.coon_id}  item title={ev.name} sx={{...awLogoSx}}>
                <RouterLink className='left' to={"/events/" + ev.coon_id + "/years/" + ev.max_year} onClick={(e) => {e.preventDefault(); navigate("/events/" + ev.coon_id + "/years/" + ev.max_year)}}>
                <LazyLoadImage
                height="100%"
                // width="100%"
                effect="blur"
                placeholderSrc={"../awards/" + ev.coon_id + "_150.png"}
                src={"../awards/" + ev.coon_id + "_150.png"}
                />
                </RouterLink>
            </Grid>
        )})}
        </Grid>
        
        </>
    );
}

export default AllAwards;
