import { Grid, Link } from "@mui/material"
import { onlLogoSx } from "../dirStyle"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Download = ({m, tr_names}) => {

  const torrPath = '../torrents/'
  const subPath = '../subtitles/'
  const mPath = '../movies/'

  const torrs = JSON.parse(m.alltors)
  const subs = JSON.parse(m.allsubs)
  const ids = JSON.parse(m.ids)

  torrs.sort((a,b) => b.size - a.size)
  subs.sort((a,b) => a.name.localeCompare(b.name))

  const torr = torrs.slice(0, 3)
  const sub = subs.slice(0, 2)

  const files = torrs.length + subs.length
  // console.log(files)

  // console.log(sub)

    return (
      <Grid item sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>

              {torr.map(t => {

              const torinfo = t.t_type.toLowerCase().replace('rip_', '').replace('rip', '')
              const torrTitle = t.name.replace(ids.export_name + '_', '').replace('.torrent', 'GB')

              function getTorLogo() {

                    return "../logo/torr_logo_" + torinfo +".png"

              }

              const torLogo = getTorLogo()

                    return(
                        <Link className="onllogo" key={t.name} href={torrPath + t.name}>
                          <Grid item sx={onlLogoSx} title={torrTitle}>
                            <LazyLoadImage
                              className="stream"
                              effect="blur"
                              delayTime={100}
                              threshold={50}
                              placeholderSrc={torLogo}
                              src={torLogo}
                              height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                            </Grid>
                        </Link>
                    )
                })}

                {sub.map(s => {

                  let subinfo 
                  s.translator ? subinfo = s.translator.replaceAll('_', ' ') + ' ' + s.s_type : subinfo = 'sub ' + s.lang + ' ' + s.s_type

                  function getLogo() {

                      let logo
                      s.translator.includes('&') ? logo = "../translators/team_logo150.png" :
                      tr_names.includes(s.translator) ? logo = "../translators/" + s.translator + "_logo150.png" :
                      s.lang === 'rus' ? logo = "../logo/sub_logo_rus70.png" : logo = "../logo/sub_logo_eng70.png"
                      
                      return logo
                  }

                  const subLogo = getLogo()

                  return (
                    <Link className="onllogo" key={s.name} href={subPath + s.name} target="_blank" download>
                        <Grid component="img" src={subLogo} title={subinfo} sx={onlLogoSx} />
                    </Link>
                  )
                  }
                  )}
                  {(files > 5) && 
                    <Link className="onllogo" href={mPath + ids.cin_id}>
                          <Grid item sx={onlLogoSx} title="more files">
                            <LazyLoadImage
                              className="stream"
                              effect="blur"
                              delayTime={100}
                              threshold={50}
                              placeholderSrc="../images/more_150.png"
                              src="../images/more_150.png"
                              height="100%"
                              width="100%"
                              style={{objectFit: "cover"}}
                              />
                            </Grid>
                        </Link>}

      </Grid>
    );
}

export default Download;
