import { Grid, Collapse } from '@mui/material';
import Streamingmobile from "./../main/StreamingMobile"
import Downloadmobile from "./../main/DownloadMobile";
import { textureSx } from "../../../theme";

const Streamcollapse = ({movie, isChecked, tr_names}) => {

    const collapseSx = {
        // bgcolor: "rgba(144, 162, 172, 0.12)",
        bgcolor: "rgba(255,255,255,0.8)"
    }

    const collapseCon = {
        borderLeft: "2px solid", 
        borderColor: "#00796B", 
    }

    const collapseTitle = {
        color: "#00796B", 
        fontSize: "3.5vw", 
        fontWeight: 700, 
        textAlign: "left"
    }

    const collapseLogos = {
        alignItems: "center", 
        m: 0, 
        textAlign: "left"
    }

    return (
              <Grid container item xs={12} sx={{...collapseSx, ...textureSx}}>
                    <Collapse in={isChecked} sx={{width: "100%"}}>
                    <Grid container item xs={12} sx={{m: "10px 0 0 10px", ...collapseCon}} >
                        <Grid item xs={6} sx={{padding: "5px 10px 5px", ...collapseTitle}}>
                            Watch online
                        </Grid>
                        <Grid item xs={6} sx={{padding: "5px 0 5px", ...collapseTitle}}>
                            Download
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} sx={{...collapseCon, m: "0 0 10px 10px"}} >
                        <Grid item xs={6} sx={{padding: "0 10px 5px", ...collapseLogos}}>
                            <Streamingmobile movie={movie} />
                        </Grid>
                        <Grid item xs={6} sx={{padding: "0 10px 5px 0", ...collapseLogos}}>
                            <Downloadmobile m={movie} tr_names={tr_names} />
                        </Grid>
                    </Grid>
                    </Collapse>
              </Grid>
    );
}

export default Streamcollapse;
