import { useNavigate, Link as RouterLink } from "react-router-dom";
import { Grid, Card, CardMedia, CardContent, Stack, Switch } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Mainmoviecardmobile = ({m, isChecked, setIsChecked}) => {

    const navigate = useNavigate()

    const posterPath = '../posters/'

    const ids = JSON.parse(m.ids)
    const main = JSON.parse(m.maininfo)
    const release = JSON.parse(m.releaseinfo)
    const posters = JSON.parse(m.posters)

    const posterSx = {
      position: "relative",
      height: "25vw", 
      maxHeight: 150,
      width: "16.8vw", 
      objectFit: "cover",
      maxWidth: 101
    }

    const titleSx = {
      fontSize: "3.1vw",
      fontWeight: 700,
      lineHeight: 1.3,
      overflow: "hidden",
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical"
  }

  
    const switchSx = {
      position: "absolute",
      alignSelf: "flex-end",
      width: 15,
      height: 15,
      padding: "14px",
      display: "flex",
      m: "0 5px 5px 0",
      '& .MuiSwitch-switchBase': {
          width: 26,
          height: 26,
          padding: "3px",
          transform: 'none',
          color: "transparent",
          ":hover": {
          bgcolor: "rgba(77, 182, 172, 0.1)"
          },
          '&.Mui-checked': {
              color: "transparent",
              transform: 'none',
              bgcolor: "rgba(77, 182, 172, 0.5)",
              '& .MuiSwitch-thumb:before': {
              backgroundImage: "url('../images/expand-after-light30.png')",
              },
              '& + .MuiSwitch-track': {
              opacity: 0.5
              },
          }
        },
      '& .MuiSwitch-thumb': {
        boxShadow: "0 0 2px 2px rgba(144, 164, 174, 0.5)",
        '&:before': {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          top: 0,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: "center",
          backgroundSize: 18,
          backgroundImage: "url('../images/expand_light30.png')",
        }
      }
    }
    
    // console.log(ref)

    return (
            <Grid container item xs sx={{textAlign: "left"}}>
                  <Card sx={{overflow: "visible", display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset"}}>
                      <div id={ids.cin_id} >
                      <CardMedia 
                        sx={posterSx}
                      >
                      <LazyLoadImage
                          effect="blur"
                          placeholderSrc={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          src={posterPath + ids.export_name + '_' + posters.coon_default + '.jpg'}
                          height="100%"
                          width="100%"
                          style={{objectFit: "cover"}}
                          />
                      </CardMedia>
                      </div>
                      <CardContent
                      sx={{width: "54vw", padding: "0 0 0 10px", "&:last-child": {padding: "0 0 0 10px"}}}
                      >
                        <Grid container sx={{height: "100%", justifyContent: "right"}}>
                            <Stack spacing={{xs:0}} sx={{width: "100%", height: "100%", justifyContent: "center"}}>
                                <Grid item sx={titleSx}>
                                    <RouterLink className='left' to={"/movies/" + ids.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + ids.cin_id)}}>
                                    {main.imdb_title_exp}
                                    </RouterLink>
                                </Grid>
                                <Grid item sx={{fontSize: "2.3vw", padding: "5px 0"}}>{main.imdb_genre}</Grid> 
                                <Grid item sx={{fontSize: "2.3vw"}}>{release.year}</Grid> 
                            </Stack>
                            <Switch 
                            checked={isChecked} onChange={() => {setIsChecked((prev) => !prev)}}
                            sx={switchSx}> 
                            </Switch>
                        </Grid>
                      </CardContent>
                  </Card>
            </Grid>
    );
}

export default Mainmoviecardmobile