import React from 'react'
import { InputLabel, MenuItem, FormControl, Select, Grid } from '@mui/material/'
import { filterConSx, filterLabelSx, selectSx, genreItemSx, firstItemSx } from '../../../theme';
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

export default function Subbed({getPath}) {

  const MenuProps: Partial<MenuPropsType> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
        },
    transformOrigin: {
        vertical: "top",
        horizontal: "right"
        },
    MenuListProps: {
        disablePadding: true
    },
    disableScrollLock: true,
    PaperProps: {
      style: {
        borderRadius: 0,
        boxShadow: "none",
        margin: "10px 0",
        maxHeight: "auto",
        color: "whitesmoke",
        // backgroundColor: "rgba(255,255,255,0.95)"
        backgroundColor: "rgba(31, 46, 54, 0.95)"
      },
    sx: {
        width: {xs: 150, sm: 200}
    }
    },
  };

  const langList = [{id: 'rus', name: 'Russian'}, {id: 'eng', name: 'English'}]

  return (
      <FormControl sx={{width: "100%", height: "100%", justifyContent: "flex-end"}}>
        <Grid sx={{...filterConSx}}>
        <InputLabel htmlFor="grouped-select" sx={{...filterLabelSx}}>Subbed</InputLabel>
        <Select defaultValue="" id="subs" label="subbed"  sx={{...selectSx}} MenuProps={MenuProps}>
              
              <MenuItem 
                onClick={() => getPath('subs', '')} 
                value=""
                sx={{...firstItemSx}}
                > Whatever </MenuItem>
              
              {langList.map((lang) => {

                    return (
                            
                            <MenuItem 
                            onClick={() => getPath('subs', lang.id)}
                            key={lang.id} 
                            value={lang.id}
                            sx={{...genreItemSx}}
                            >
                            {lang.name}
                            </MenuItem>
                            
                    )
                })}

        </Select>
        </Grid>
      </FormControl>
  );
}