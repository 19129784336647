import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid } from '@mui/material';
import { filterConSx, filterLabelSx, selectSx, genreItemSx } from '../../../theme';
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

export default function Sortby({getPath}) {

  const subHeaderStyle = {
    color: "whitesmoke", 
    backgroundColor: "transparent",
    fontSize: {xs: 11, md: 14}
  }

  const MenuProps: Partial<MenuPropsType> = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "right"
        },
    transformOrigin: {
        vertical: "top",
        horizontal: "right"
        },
    MenuListProps: {
        disablePadding: true
    },
    disableScrollLock: true,
    PaperProps: {
      style: {
        borderRadius: 0,
        boxShadow: "none",
        margin: "10px 0",
        maxHeight: "auto",
        color: "whitesmoke",
        // backgroundColor: "rgba(255,255,255,0.95)"
        backgroundColor: "rgba(31, 46, 54, 0.95)"
      },
    sx: {
        width: {xs: 150, sm: 200}
    }
    },
  };

  const ratings = [
    {name: "Cinemacoon", id: "coon_score"},
    {name: "IMDb", id: "imdb_score"},
    {name: "Metacritic", id: "meta_score"},
    {name: "Rotten Tomatoes", id: "rt_score"}
  ];

// const votes = [
//   {name: "IMDb", id: "imdbVotes"},
//   {name: "Metacritic", id: "metaVotes"},
//   {name: "Rotten Tomatoes", id: "rtVotes"}
// ];

  return (
      <FormControl sx={{width: "100%", height: "100%", justifyContent: "flex-end"}}>
        <Grid sx={{...filterConSx}}>
        <InputLabel htmlFor="grouped-select" sx={{...filterLabelSx}}>Sort by</InputLabel>
        <Select defaultValue="" id="grouped-select" label="sort"  sx={{...selectSx}} MenuProps={MenuProps}>

              <MenuItem onClick={() => getPath('sort', 'sub_date')} value="subDate" sx={{...genreItemSx, padding: "7px 0 7px 15px"}}>Date of translation</MenuItem>
              
              <ListSubheader sx={subHeaderStyle}>Ratings</ListSubheader>

              {ratings.map((agg) => {
                    return (
                            <MenuItem 
                            onClick={() => getPath('sort', agg.id)}
                            key={agg.id} 
                            value={agg.id}
                            sx={{...genreItemSx}}
                            >{agg.name}</MenuItem>
                    )
                })}


              {/* <ListSubheader sx={subHeaderStyle}>Votes</ListSubheader>

              {votes.map((agg) => {
                    return (
                            <MenuItem 
                            onClick={() => sortFunc(agg.id)} 
                            key={agg.id} 
                            value={agg.id}
                            sx={{...genreItemSx}}
                            >{agg.name}</MenuItem>
                    )
                })} */}
        </Select>
        </Grid>
      </FormControl>
  );
}