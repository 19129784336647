import React, { useState } from 'react';
import { Grid, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { selectSx, itemSx, sorterConSx } from './awStyle';
import { MenuProps as MenuPropsType } from "@mui/material/Menu";

function Sorter({award, years, setParams, location, param, params, noms, pathtype}) {

    const defaultAw = award[0]
    // console.log(defaultAw)

    const vars = ['Years', 'Nominations']

    const navigate = useNavigate()

    const [select, setSelect] = useState(pathtype)

    const path = location.pathname.replace(pathtype + "/" + param, '')
    // console.log(param)

    const MenuProps: Partial<MenuPropsType> = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
            },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
            },
        MenuListProps: {
            disablePadding: true
        },
        disableScrollLock: true,
        PaperProps: {
          style: {
            padding: 0,
            borderRadius: 0,
            boxShadow: "none",
            margin: "10px 0",
            maxHeight: "50vh",
            color: "whitesmoke",
            // backgroundColor: "rgba(255,255,255,0.95)"
            backgroundColor: "rgba(31, 46, 54, 0.95)"
          },
        sx: {
            width: {xs: 150, sm: 'auto'},
            scrollbarColor: "grey silver",
            scrollbarWidth: "thin"
        },

        },
      };

         // FILTER & SORT

        const getPath = (e) => {

            setParams({...params, name: e})
            navigate(path + pathtype + "/" + e)
        }

        const getSelect = (a) => {
            setSelect(a)

            if (a === 'nominations') { 
                setParams({type: a, name: defaultAw.c_coon_id})
                navigate(path + "nominations/" + defaultAw.c_coon_id)}
            else {
                setParams({type: a, name: defaultAw.year})
                navigate(path + "years/" + defaultAw.year)}
            }

    return (
 
      <Grid container sx={{...sorterConSx}}>
            <Grid sx={{display: 'flex', justifyContent: "space-around", padding: {xs: '0 5px', sm: 0}, width: {xs: 'auto'}, minHeight: '30px'}}>
                <Select key={pathtype} defaultValue={pathtype ?? null} label="" sx={selectSx} MenuProps={MenuProps}>

                    {vars.map((va) => {

                        const v = va.toLowerCase()
                        return (
                            <MenuItem 
                            onClick={() => getSelect(v)} 
                            key={v} 
                            value={v ?? null}
                            sx={{...itemSx}}
                            >{va}</MenuItem>
                        )
                    })}
                </Select>
            </Grid>
            
            <Grid sx={{display: 'flex', justifyContent: "space-around", padding: 0, width: {xs: 'auto'}, minHeight: '30px'}}>
                {years.length + noms.length > 0 &&
                <Select key={param} defaultValue={param ?? null} label="" sx={selectSx} MenuProps={MenuProps}>

                    {pathtype === 'years' ?
                     years.map((v) => {
                        return (
                            <MenuItem 
                            onClick={() => getPath(v)} 
                            key={v} 
                            value={v ?? null}
                            sx={{...itemSx}}
                            >{v}</MenuItem>
                        )
                    })
                    :
                    noms.map((v) => {
                        return (
                            <MenuItem 
                            onClick={() => getPath(v.id)} 
                            key={v.id} 
                            value={v.id ?? null}
                            sx={{...itemSx}}
                            >{v.name}</MenuItem>
                        )
                    })
                }
                </Select>
                }
            </Grid>
      </Grid>

    )
}

export default Sorter