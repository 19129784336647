import { createTheme } from '@mui/material/styles';
import { blueGrey } from "@mui/material/colors";

const bgTextureSecondary = "url('../../../images/transparent-square-tiles.png')"

// GLOBAL THEME 

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1536
        }
    },
    palette: {
        primary: {
            main: blueGrey[50],
        },
        secondary: {
            light: blueGrey[400],
            main: blueGrey[900]
        },
        info: {
            main: blueGrey[100],
            dark: blueGrey[300]
        },
        background: {
            // default: blueGrey[900],
            default: "#1F2E36"
        }

    },
    components: {
        MuiCssBaseline: {
          styleOverrides: {
              body: {
                backgroundImage: bgTextureSecondary
              }
          }
        }
    },
    typography: {
        fontFamily: "'Mulish', sans-serif", //неплохо, чуть более обычный

        h1: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "2.6rem",
            fontWeight: 700,
            textTransform: "uppercase"
        },
        h2: {
            fontFamily: "'Montserrat', sans-serif",
            fontSize: "1.8rem",
            fontWeight: 600,
            mb: 0
        },
        h3: {
            fontSize: "1.4rem",
            fontWeight: 800,
        },
        h4: {
            fontSize: "1.3rem",
            fontWeight: 400,
        },
        body1: {
            fontSize: "14px",
            fontWeight: 500
        }
    }
});

const textureSx = {
    backgroundImage: bgTextureSecondary
}

// LOGIN & SIGNUP 

const inputSx = {
    input:{color: "primary.main", bgcolor: "rgba(255,255,255,0.1)"}, 
    "& .MuiInputLabel-root":{color: "info.main" }, 
    "& .MuiInputLabel-root.Mui-focused":{color: "primary.main" }, 
    "& .MuiOutlinedInput-root": {"& > fieldset": {borderColor: "info.main"}},
    "& .MuiOutlinedInput-root:hover": {"& > fieldset": {borderColor: "primary.main"}},
    "& .MuiOutlinedInput-root.Mui-focused": {"& > fieldset": {borderColor: "info.main"}},
    "& .MuiOutlinedInput-root.Mui-focused:hover": {"& > fieldset": {borderColor: "primary.main"}}
}

const buttonSx = {
    mt: 3, 
    color: "secondary.main", 
    backgroundColor: "info.main", 
    fontSize: "1.1rem",
    fontWeight: 700,
    ":hover": {
       color: "primary.main", 
       backgroundColor: "info.dark"},
    mb: 2, 
    minHeight: '56px', 
    m: "0",
    ":disabled": {bgcolor: "rgba(255,255,255,0.4)", color: "info.dark"}
}

const linkSx = {
    color: "info.main",
    ":hover": {
        color: "info.dark"}
}

const coonLogoSx = {
    bgcolor: "rgba(252, 252, 252, 0.3)",
    border: "2px solid rgba(252, 252, 252, 0.7)",
    borderRadius: "50%",
    m: "0 0 3%",
    height: { xs: 120, sm: 140 },
    width: { xs: 120, sm: 140 }
}

// TRANSLATORS

const transConSx = {
    color: "primary.main",
    width: "100%",
    m: "10px 0 0",
    backgroundImage: bgTextureSecondary
}

const transLogoSx = {
    height: {xs: "20vw", sm: "16.66vw", md: 150},
    width: {xs: "20vw", sm: "16.66vw", md: 150}
}

const transMenuSx = {
    padding: {xs: "0 10px", sm: "0 3.5vw", md: "0 30px"},
    bgcolor: "rgba(237, 230, 219, 0.06)",
    width: {xs: "28vw", md: 270},
    fontSize: {xs: "2vw", md: 17},
    fontWeight: 700,
    alignItems: "center",
    textTransform: "uppercase"
}

const transMenuItemSx = {

}

// NAVBAR & YEARSLIDER & SORTER 

const navbarSx = {
    // color: "secondary.main",
    color: "primary.main",
    backgroundColor: "background.default",
    backgroundImage: bgTextureSecondary,
    fontWeight: 700,
    width: "100%",
    height: {xs: "11vw", md: 64},
    maxHeight: 64
}

const filterConSx = {
    display: "flex", 
    justifyContent: "center", 
    height: "100%", 
    ":hover": {
        bgcolor: "rgba(255,255,255,0.3)"}
}

const filterLabelSx = {
    // fontSize: "16px",
    position: "relative", 
    paddingRight: "20px",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "primary.main", 
    transform: "none",
    transition: 0,
    fontSize: {xs: 11, md: 14},
    "&.MuiFormLabel-filled": {
        color: "transparent",
        // borderTop: "2px solid",
        // borderTopColor: "info.dark",
        // borderBottom: "2px solid",
        // borderRightColor: "info.dark",
        bgcolor: "secondary.light"
        
    },
    // "&.Mui-focused": {
    //     transform: "translate(30px, -11px) scale(0.7)"}
}

const selectSx = {
    "& .MuiSvgIcon-root": {
        color: "primary.main",
      },
      color: "primary.main",
      zIndex: "100",
      position: "absolute",
      height: "100%",
      width: "100%",
      padding: {xs: "0 4px 0 2px", md: "0 12px 0 6px"},
      "& fieldset": {border: "none"},
    //   "& fieldset.MuiOutlinedInput-notchedOutline": {
    //     border: "2px solid",
    //     borderColor: 'yellow'},
      // ':hover': {
      //   "& fieldset.MuiOutlinedInput-notchedOutline": {
      //   borderColor: 'yellow'}
      // },
    //   '&.Mui-focused': {
    //     "& fieldset.MuiOutlinedInput-notchedOutline": {
    //     border: "2px solid",
    //     borderColor: 'yellow'}
    //   },
      "& .MuiOutlinedInput-input": {
        fontSize: {xs: 11, md: 14}, 
        padding: 0}
}

const firstItemSx = {
    minHeight: 20,
    borderBottom: "2px solid", 
    borderBottomColor: "info.dark", 
    fontSize: {xs: 11, md: 14},
    padding: {xs: "0.8vw 16px", md: "7px 16px"},
    "&.Mui-selected": {
        ":hover": {
        color: "secondary.light",
        backgroundColor: "info.main"}}
}

const genreBigItemSx = {
    minHeight: "auto",
    fontSize: {xs: 11, md: 14},
    padding: {xs: "0.8vw 16px", md: "7px 16px"},
    ":hover": {
        color: "primary.main", 
        backgroundColor: "info.dark"},
    "&.Mui-selected": {
        color: "primary.main", 
        backgroundColor: "info.dark",
        ":hover": {
            backgroundColor: "info.main",
            color: "info.dark"
        }
    }
}

const genreItemSx = {
    fontSize: {xs: 12, md: 14},
    minHeight: "auto",
    padding: "3px 36px",
    ":hover": {
        color: "primary.main", 
        backgroundColor: "info.dark"},
    "&.Mui-selected": {
        color: "primary.main", 
        backgroundColor: "info.dark",
        ":hover": {
            backgroundColor: "info.main",
            color: "info.dark"
        }
    }
}

// MAIN MOVIE CARD 

const posterSx = {
    position: "relative",
    height: {xs: "16.66vw", md: 150}, 
    maxHeight: 150,
    width: {xs: "11.22vw", md: 101}, 
    mr: "10px", 
    // imageRendering: "high-quality",
    transition: 0, 
    "&:hover": {
        zIndex: 100,
        // boxShadow: {xs: "none", sm: "0 0 2px 2px white"}, 
        // transform: {xs: "scale(1.5) translate(12px, 0)", sm: "scale(1.75}" md: "scale(2)"},
        transform: {xs: "scale(1.8) translate(12px, 0)", md: "scale(1.8)"},
        transition: "0.2s"
    }
}

const titleSx = {
    fontSize: { xs: "2.1vw", md: 20 }, 
    fontWeight: 700,
    lineHeight: 1.2,
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical"
}

// MAIN RATES 

const ratesSx = {
    fontSize: { xs: '2.4vw', sm: "1.65vw", md: 16}, 
    width: {xs: '27vw', sm: "20.83vw", md: 200}, 
    fontWeight: 600
}

const aggLogoSx = {
    width: {xs: 12, sm: 14, md: 17},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.2)",
        opacity: "70%"}
}

// MAIN STREAMING & DOWNLOAD 

const onlLogoSx = {
    padding: "2px", 
    width: { xs: "6.0vw", sm: "5.0vw", md: 45},
    maxWidth: {sm: 40, md: 45},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.1)",
        opacity: "70%"}
}

const subLogoSx = {
    padding: "2px", 
    width: { xs: "6.0vw", sm: "5.0vw", md: 45},
    maxWidth: {sm: 40, md: 45},
    borderRadius: "50%",
    ":hover": {
        transform: "scale(1.1)"}
}

const coonSx = {
    height: { xs: "6.5vw", sm: "5.5vw", md: 45},
    maxHeight: {sm: 36, md: 50}
}




export { theme, inputSx, buttonSx, linkSx, navbarSx, titleSx, textureSx,
    ratesSx, aggLogoSx, onlLogoSx, subLogoSx, coonSx, coonLogoSx, transLogoSx, transConSx,
    transMenuItemSx, transMenuSx, posterSx, filterConSx, filterLabelSx, selectSx, genreBigItemSx, genreItemSx, firstItemSx}