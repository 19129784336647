import { Link as RouterLink } from "react-router-dom";
import { Grid, Card, CardMedia, CardContent, Stack } from "@mui/material"
import { mainSx, posterSx, titleSx, conSx, subLogoSx, fontBasicSx } from "../singleaw/awStyle"
import { LazyLoadImage } from 'react-lazy-load-image-component';

const SingleAwYear = ({aw, navigate, posterPath, setParams}) => {

    return (
        
                <Grid>

                    <Grid container sx={{...conSx}}>
                    {aw.nominees.map(n => {

                    const getPath = () => {

                    setParams({type: 'years', name: aw.year})
                    navigate("/events/" + aw.coon_id + "/years/" + aw.year)
                    }
                        
                    return (

                    n.is_win &&
                    <Grid key={n.nom_id} container sx={{...mainSx}}>
                        <Card sx={{overflow: "visible", width: '100%', display: "flex", bgcolor: "transparent", color: "primary.main", boxShadow: "unset", padding: 0}}>
                            <CardMedia 
                                // id={ids.cin_id}
                                sx={{...posterSx}}
                            >
                                {n.cin_id ?
                                <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}
                                >
                                <LazyLoadImage
                                effect="blur"
                                placeholderSrc={posterPath + n.export_name + '_' + n.coon_default + '.jpg'}
                                src={posterPath + n.export_name + '_' + n.coon_default + '.jpg'}
                                height="100%"
                                width="100%"
                                style={{objectFit: "cover"}}
                                />
                                </RouterLink>
                                :
                                <LazyLoadImage
                                effect="blur"
                                placeholderSrc={"../../../images/no_poster.png"}
                                src={"../../../images/no_poster.png"}
                                height="100%"
                                width="100%"
                                style={{objectFit: "cover"}}
                                />
                                }
                            </CardMedia>
                            <CardContent sx={{width: {xs: '83vw', sm: '88%'}, "&:last-child": {padding: "0 0 0 10px"}}}>
                                <Grid container sx={{height: "100%", justifyContent: 'space-between'}}>
                                <Stack spacing={{xs:0}} sx={{height: "100%", width: '80%', justifyContent: "center"}}>
                                    <Grid item sx={{...titleSx}}>
                                        {n.cin_id ?
                                        <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                        {n.title_exp}
                                        </RouterLink>
                                        :
                                        <Grid sx={{color: 'silver'}}>
                                        {n.title_exp}
                                        </Grid>
                                        }
                                    </Grid>
                                    {n.cin_id ?
                                        <Grid item sx={{...fontBasicSx, padding: {xs: "5px 0", lg: "6px 0"}}}>{n.director}</Grid>
                                        :
                                        <Grid item sx={{...fontBasicSx, padding: {xs: "5px 0", lg: "6px 0"}, color: 'silver'}}>{n.director}</Grid>
                                        }
                                    <Grid item sx={fontBasicSx}>{n.imdb_genre}</Grid> 
                                </Stack>
                                <Grid item sx={{alignSelf: 'end', height: '100%', padding: '10px'}}>
                                    <Stack spacing={{xs:0}} sx={{height: "100%", justifyContent: "space-between"}}>
                                    {(n.sub_lang && n.sub_lang === 'rus') ?
                                    <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                    <Grid 
                                        component="img" src={"../../../logo/sub_logo_rus70.png"} sx={{...subLogoSx, mt: 0}}>
                                    </Grid>
                                    </RouterLink>
                                    : (n.sub_lang && n.sub_lang === 'eng') ?
                                    <RouterLink className='left' to={"/movies/" + n.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + n.cin_id)}}>
                                    <Grid 
                                        component="img" src={"../../../logo/sub_logo_eng70.png"} sx={{...subLogoSx, mt: 0}}>
                                    </Grid>
                                    </RouterLink>
                                    :
                                    <Grid 
                                        sx={{...subLogoSx, mt: 0}}
                                    />
                                    }
                                    
                                    <Grid sx={{...titleSx, padding: {md: '0 0 7px'}}}>
                                        <RouterLink className='left' to={"/events/" + aw.coon_id + "/years/" + aw.year} onClick={(e) => {e.preventDefault(); getPath()}}>
                                        {aw.year}
                                        </RouterLink>
                                    </Grid>
                                    
                                    </Stack>
                                </Grid>
                                </Grid>
                                
                            </CardContent>
                        </Card>
                    </Grid>
                    )})}
                    </Grid>
                    
                </Grid>
)
            
}

export default SingleAwYear;
