import { useState, useEffect } from "react";
import { Autocomplete, TextField, Link } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import _debounce from "lodash.debounce"
import axios from 'axios'
// import { useNavigate, Link as RouterLink } from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.45),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.85),
    color: "grey"
  },
  marginLeft: 0,
  width: '100%',
  height: "100%",
  [theme.breakpoints.up('xs')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
  display: "flex"
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(Autocomplete)(({ theme }) => ({
  color: 'inherit',
  '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    height: '48px',
    padding: theme.spacing(0, 0, 0, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    width: '12ch',
    transition: theme.transitions.create('width'),
    // width: '12ch',
      '&:focus': {
        width: '30ch',
      },
  },
  '& .MuiInput-root': {
    color: 'rgb(236, 239, 241)',
    height: '48px',
    '&:hover': {
      color: 'grey'
    }
  },
  '& .css-wb57ya-MuiFormControl-root-MuiTextField-root': {
    height: '48px'
  }
}));

const filterOptions = createFilterOptions({
  stringify: (option) => option.name + option.id
});

const MyAutoComplete = () => {

  // const navigate = useNavigate()

  const [item, setItem] = useState();
  // const [open, setOpen] = useState(false);

  // ---------------------- SEARCH -----------------------------

  const [input, setInput] = useState("");

  function slowHandler (e, v) {
    setInput(v);
  };

  // const inputHandler = useCallback(_debounce(slowHandler, 100), [])

  const [coonbase, setCoonbase] = useState([])

  useEffect(() => {
      const getBase = async () => {
          try {
              const res = await axios.post("/api/coon/singlesearch", {params: input}, {
                  responseType: 'json',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                  }
                })
              // console.log(res.data)
              setCoonbase(res.data.full)
          } catch (err) {
              console.log(err)
              const log = {type: 'error', text: `${err}`}
              await axios.post("/api/clientlogs", log)
      }
      }
      getBase()
  }, [input])


  const items = [...new Set([...coonbase.map(m => {
    return {key: m.item.imdb_id, cin_id: m.item.cin_id, name: m.item.imdb_title_exp + " (" + m.item.year + ", " + m.item.director + ")"}})])]

  // console.log(items)

  return (
    <Search>
        <SearchIconWrapper>
           <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          disableClearable
          forcePopupIcon={false}
          options={items}
          getOptionLabel={(option) => option.name || ""}
          renderOption={(props, option) => (
            <li {...props}>
              <Link 
              href={'/movies/' + option.cin_id} 
              sx={{
                justifyContent: 'left',
                color: 'rgb(0, 0, 0, 0.9)',
                '&:hover': {
                  color: 'rgb(236, 239, 241)',
                  textDecoration: 'none'
                }
              }}
              >
              {option.name}
              </Link>
              {/* <RouterLink to={"/movies/" + option.cin_id} onClick={(e) => {e.preventDefault(); navigate("/movies/" + option.cin_id)}}>
              {option.name}
              </RouterLink> */}
            </li>
          )}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={item}
          onInputChange={slowHandler}
          filterOptions={filterOptions}
          inputValue={input}
          onSelect={(e, v) => setItem(v)}
          renderInput={(params) => (
            <TextField {...params} placeholder='Search...' variant="standard" />
          )}
          componentsProps={{
            paper: {
              sx: {
                width: 'inherit',
                backgroundColor: 'rgb(236, 239, 241, 0.85)',
                "& .MuiAutocomplete-noOptions": {
                  display: 'none'
                },
                "& .MuiAutocomplete-option, .MuiAutocomplete-option[aria-selected='true'], .MuiAutocomplete-option.Mui-focused": {
                    '&:hover': {
                    color: 'rgb(236, 239, 241)',
                    backgroundColor: 'rgb(144, 164, 174, 0.8)'
                  }
                }
              }
            }
          }}
        />
    </Search>
  );
};

export default MyAutoComplete;
