import React, {useState, useCallback, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import _debounce from "lodash.debounce"
import Sorter from "./../components/years/Sorter";
import MainHeader from "./../components/years/MainHeader";
import Main from "../components/years/Main";
import Navbar from '../components/navbar/Navbar';
import Yearslider from '../components/years/Yearslider';
import axios from "axios"
import Fuse from "fuse.js";
import { Grid } from '@mui/material';
import { textureSx } from "../theme";

const Years = () => {

    // ------------------ FILTER BY YEAR --------------------------

    const location = useLocation()
    const pageYear = location.pathname.split("/")[2]
    // console.log(params)
    const [filters, setFilters] = useState('')

    const [coonbase, setCoonbase] = useState([])
    const [limit, setLimit] = useState(50)

    // ---------------------- SEARCH -----------------------------

    const [inputText, setInputText] = useState("");
    function slowHandler (e) {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
    };

    const inputHandler = useCallback(_debounce(slowHandler, 500), [])

    const params = {loc: location, input: inputText, limit: limit}

    useEffect(() => {
            const getBase = async () => {
                const res = await axios.post(`/api/coon/${pageYear}`, params, {
                    responseType: 'json',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                    }
                  })
                // console.log(res)
                setCoonbase(res.data.cut)
            }
            getBase()
        }, [location, inputText, limit])
     
    const result = coonbase
    // found.length === 0 && inputText === "" ? result = base.slice(0, limit) : result = found.slice(0, 20)

    const mainSx = {
      padding: "14px", 
      m: "9px auto", 
      justifyContent: 'center',
      color: "primary.main", 
      width: {xs: "100%", md: 960},
      fontSize: {xs: "1.875vw", md: 16},
      bgcolor: "rgba(237, 230, 219, 0.06)",
      ':hover': {
        color: "#bdd4d2",
        bgcolor: "rgba(237, 230, 219, 0.12)"
      }
      // bgcolor: "rgba(236, 239, 241, 0.95)",
  }

    return (
        <>
                <Navbar inputHandler={inputHandler}/>
                <Yearslider pageYear={pageYear} filters={filters} />
                <Sorter location={location} setLimit={setLimit} filters={filters} setFilters={setFilters}/>
                <MainHeader />
                {result.map(r => {

                     let movie
                     r.item ? movie = r.item : movie = r

                     return (
                     <Main key={movie.imdb_id} movie={movie} />
                     )
                 })} 
                 {limit <= coonbase.length && 
                 <Grid 
                 container 
                 onClick={() => setLimit(limit + 50)}
                 sx={{...mainSx, ...textureSx, cursor: 'pointer'}}>
                 see more
                 </Grid>}
         </>
    )
    
}

export default Years;
