import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

    const Search = styled('div')(({ theme }) => ({
        position: 'absolute',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: "transparent",
        margin: "0 40px",
        ":hover": {
          color: "rgba(0, 137, 121, 1)"
        },
        ':focus-within': {
          margin: 0,
          width: "100vw",
          backgroundColor: alpha(theme.palette.common.white, 0.95),
        },
        zIndex: 100,
        height: "100%",
        display: "flex",
        [theme.breakpoints.up('md')]: {
          ':hover, :focus-within': {
            margin: 0,
            width: "100%",
            backgroundColor: alpha(theme.palette.common.white, 0.95),
          },
        }
      }));
      
      const SearchIconWrapper = styled('div')(({ theme }) => ({
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        '&:hover': {
          padding: 0,
          position: "relative"
        },
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }));
      
      const StyledInputBase = styled(InputBase)(({ theme }) => ({
        color: 'inherit',
        width: "100%",
        height: "100%",
        '& .MuiInputBase-input': {
          // vertical padding + font size from searchIcon
          paddingLeft: `calc(1em + ${theme.spacing(4)})`,
          width: 0,
          ":focus": {
            width: '100%',
          },
        },
      }));
      
      export default function SearchAppBar({inputHandler}) {
        return (
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase
                    onChange={inputHandler}
                    placeholder="Search…"
                    inputProps={{ 'aria-label': 'search' }}
                  />
                </Search>
        )
      }

