import { useState, useEffect } from "react"
import { Grid, Link } from '@mui/material';
import { Header, onlLogoSx, StreamWrap } from '../movieStyle';
import axios from "axios"

const Downl = ({movie}) => {

    const torrPath = '../torrents/'
    const subPath = '../subtitles/'

    const translators = [
      "24fps",
      "Chacun_son_cinema",
      "Chacun_son_cinema_&_FOCS",
      "Cinemacoon",
      "Cinemacoon_&_Dark_Alice",
      "Cinemacoon_&_MENINSUB",
      "Cool_Story_Blog",
      "Dark_Alice",
      "Dark_Alice_&_KATYA",
      "Dark_Alice_&_Sergei_Kubryak",
      "Directors_Card",
      "FOCS",
      "FOCS_&_homoSUBiens",
      "FOCS_&_RebelProject",
      "Gyarandu",
      "homoSUBiens_&_one_inch_tall_&_RebelProject",
      "KATYA",
      "Kleinzeit",
      "MENINSUB",
      "one_inch_tall",
      "RebelProject",
      "Scandiscope",
      "serpentarium",
      "SubbedAsFuck",
      "Timur_Bokov",
      "vaileila"
    ]

    // GETTING TORRENTS

    const [tors, setTors] = useState([])

    useEffect(() => {
      const getTors = async () => {
          const res = await axios.get("/api/torrents/")
          setTors(res.data)
      }
      getTors()
    }, [])

    const filmTors = [...new Set(tors.filter(s => s.includes(movie.ids.export_name)))]

   // GETTING SUBS

    const [subs, setSubs] = useState([])

      useEffect(() => {
        const getSubs = async () => {
            const res = await axios.get("/api/subtitles/")
            setSubs(res.data)
        }
        getSubs()
    }, [])

    const filmSubs = [...new Set(subs.filter(s => s.includes(movie.ids.export_name)))]

    return (
            <StreamWrap>
                <Header>Download</Header>

                {filmTors.map(torr => {

                  const info = torr.replace(movie.ids.export_name + '_', '').replace('.torrent', 'GB')
                  const torrinfo = info.split('_').slice(0,2).join('').toLowerCase().replace('rip','').replace('264','')

                  function getTorLogo() {

                        return "../logo/torr_logo_" + torrinfo +".png"

                  }

                  const torLogo = getTorLogo()

                  return (
                  <Link className="sublogo" key={torr} href={torrPath + torr}>
                      <Grid component="img" title={info} src={torLogo} sx={onlLogoSx} />
                  </Link>
                  )
                }
                )}

               {filmSubs.map(sub => {

                  const subInfo = sub.replace(movie.ids.export_name, '').replace('.srt', '')
                  
                  function getLogo() {
                      
                      let tr = sub.replace(movie.ids.export_name, '')
                      tr = tr.slice(5, tr.length - 4)
                      
                      if (tr.includes("web") || tr.includes("blr") || tr.includes("dvd") || tr.includes("all")) {
                        tr = tr.slice(4, tr.length)
                      }

                      if (tr.includes("hdtv")) {
                        tr = tr.slice(5, tr.length)
                      }

                      if (tr.includes(".")) {
                      tr = tr.slice(0, tr.search(/\./) - 2)}
                      
                      let lastletter = tr.slice(tr.length - 1, tr.length)
                      if (lastletter.includes("_")) {
                        tr = tr.slice(0, tr.length - 1)
                      }

                      if (translators.includes(tr)) {
                         return "../translators/" + tr + "_logo150.png"
                      } else {
                          if (sub.includes("_rus")) {
                            return "../logo/sub_logo_rus70.png"
                          } else if (sub.includes("_eng")) {
                            return "../logo/sub_logo_eng70.png"
                          }
                      }
                  }
                  
                  const subLogo = getLogo()

                  return (
                    <Link className="sublogo" key={sub} href={subPath + sub} target="_blank" download>
                        <Grid component="img" src={subLogo} title={'sub' + subInfo} sx={onlLogoSx} />
                    </Link>
                  )
                }
               )}
            </StreamWrap>
    );
}

export default Downl;
